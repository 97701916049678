* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.nav {  
  color: white;  
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  text-decoration: underline;
}

.nav li:hover {
  text-decoration: underline;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.companyimage {
  height: 40px;
  width: 40px;
}

.companyname {
  font-size: 2rem;
}

#topRow,
#aboutTopRow,
#abouthBottomRow {
  padding-bottom: 10px;
}

#aboutTopDiv img,
#middleRow img,
#topDiv img {
  max-width: 90%;
  margin-top: 20px;  
}

#topDiv p,
#middleRow p {
  padding-left: 5px;
  margin-right: 50px;
}

#topDiv h2 {
  padding: 10px 0px 0px 5px;
}

#middleRow {
  margin: 0px;
  padding-top: 0px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-left: 40px;
  background-color: burlywood;
}

#aboutMiddleRow {
  margin: 0px;
  padding-bottom: 10px;
  background-color: burlywood;
}

.aboutText h2 {
  padding-top: 70px;
  padding-left: 40px;
}

.aboutText p {
  padding-left: 40px;
}

.aboutText h6 {
  padding-left: 40px;
}

.aboutText ul {
  padding-left: 60px;
}

#contactDiv {
  padding-top: 50px;
  padding-bottom: 10px;
}

.form-group label {
  font-weight: bold;
}

.form-group input {
  width: 500px;
}

.form-group textarea {
  width: 800px;
  height: 200px;
}